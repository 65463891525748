.preview {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--palette-background-dark-alt);
  justify-items: center;
}

.preview span:first-of-type {
  justify-self: start;
}

.preview span:last-of-type {
  justify-self: end;
}

.column {
  color: var(--color-yellow);
  font-size: var(--text-copy);
  padding: var(--spacing-2);
}

.column a {
  text-decoration: underline;
  text-decoration-color: inherit;
  text-decoration-thickness: 0.125em;
  text-underline-offset: 1.5px;
}

.column a:hover {
  color: var(--palette-text-dark);
  text-decoration-color: var(--palette-text-dark);
}
