.section {
  display: grid;
  gap: 1rem;
}

.container {
  display: flex;
  flex-flow: row wrap;
  border: solid 1px var(--color-black);
  border-radius: 0.125rem;
  overflow: hidden;
}

.container label {
  flex: 2 1 auto;
}

.button {
  background: var(--color-black);
  color: var(--color-white);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  flex: 1 1 auto;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
.button:focus {
  outline: 0;
}

.input {
  background: var(--transparent);
  color: var(--color-black);
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1em;
  line-height: 1;
  width: 100%;
}

.input:focus {
  outline: none;
}

.checkboxSection {
  width: 100%;
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxSection label {
  cursor: pointer;
  margin-right: 1rem;
  line-height: 1;
  display: flex;
  align-items: center;
}

.errorsContainer {
  display: grid;
  grid-gap: 0.25rem;
}

.errorsMessage {
  font-weight: 200;
  font-size: 0.875rem;
}

.toggle {
  text-align: left;
  font-size: 0.625rem;
  text-decoration: underline;
  width: fit-content;
}

.disclaimer {
  font-size: 0.625rem;
  opacity: 0;
  height: 0;
  transition: opacity 250ms;
  transition-delay: 100ms;
}
.sms {
  opacity: 1;
  height: auto;
}
.disclaimer a {
  text-decoration: underline;
}
