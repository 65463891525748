.container {
  grid-column: 1;
  position: relative;
}

.list {
  width: inherit;
  margin: 0;
  border: none;
  background: var(--color-white);
  color: var(--color-black);
  list-style: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-size: 12px;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--z-layer-9);
}

.list:focus {
  outline: none;
}

.listItem {
  list-style: none;
  margin: 0;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  border-radius: 2px;
  width: inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.listItem svg {
  flex: 1 1 auto;
  height: 11px;
  margin-right: 6px;
}
.listItem:hover {
  cursor: pointer;
}

.highlighted {
  background: #a0aec0;
}
.button {
  width: inherit;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button svg {
  flex: 1 1 auto;
  height: 0.75rem;
  margin-right: 6px;
  fill: var(--color-white);
}

.button:hover {
  cursor: pointer;
}
.button:focus {
  outline: none;
}
