.wrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-content: center;
  grid-template-columns: repeat(auto-fit, 3rem);
  place-items: center center;
  grid-template-rows: 3rem;
}

.wrapper a {
  font-size: 0.875rem;
  cursor: pointer;
}

.link {
  font-size: 0.875rem;
  line-height: 1rem;
  align-self: stretch;
  display: flex;
  justify-content: center;
}

.bagCount {
  font-family: var(--font-sans);
  font-size: var(--text-fine);
  position: absolute;
  bottom: 15px;
}


@media (min-width: 35em) {
  .wrapper {
    gap: 0;
    grid-template-columns: auto;
  }
  .wrapper a {
    line-height: 1rem;
    padding: 1rem 0.5rem;
  }
}
