.navButton {
  width: 100%;
  height: 2rem;
}

.wrapper {
  overflow: scroll;
}

.form {
  height: 100vh;
  width: 100%;
}

@media (min-width: 35em) {
  .navButton {
    width: 150px;
  }
}