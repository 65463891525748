.container {
  overflow-y: auto;
  flex: 1 1 auto;
}

.wrapper {
  padding: 1rem;
}

/* ======================================================= */
/* Scrollbar styling for Firefox */

.container {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #7e7e7e #e8e8e8; /* scroll thumb and track */
}

/* ======================================================= */

/* ======================================================= */
/* Scrollbar styling for Chrome, Edge, and Safari */

.container::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
}

.container::-webkit-scrollbar-track {
  background: #e8e8e8; /* color of the tracking area */
}

.container::-webkit-scrollbar-thumb {
  background-color: #7e7e7e; /* color of the scroll thumb */
  border-radius: 2px; /* roundness of the scroll thumb */
  border: 0; /* creates padding around scroll thumb */
}
/* ======================================================= */

.list {
  display: grid;
  gap: 1rem;
}

@media (min-width: 45em) {
  .list {
    gap: 1.5rem;
  }
}
