.container {
  cursor: pointer;
  padding-right: 0.5em;
}

.container input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  margin: 0; /* Not removed via appearance */
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-gray);
  border-radius: 2px;
  display: grid;
  place-content: center;
}

.container input[type="checkbox"]:hover {
  cursor: pointer;
}

.container input[type="checkbox"]::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.695 2.28A1 1 0 0 0 .28 3.696l2 2a1 1 0 0 0 1.414 0l4-4A1 1 0 0 0 6.28.28L2.988 3.574 1.695 2.28Z' fill='%23fff' /%3E%3C/svg%3E");
  display: none;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-black);
  border-radius: 4px;
  box-shadow: inset 1em 1em var(--color-black);
}

.container input[type="checkbox"]:checked::before {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* This targets firefox */
/* The height of the svg was being displayed differently on different browsers */
@-moz-document url-prefix() {
  .container input[type="checkbox"]::before {
    content: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.695 2.28A1 1 0 0 0 .28 3.696l2 2a1 1 0 0 0 1.414 0l4-4A1 1 0 0 0 6.28.28L2.988 3.574 1.695 2.28Z' fill='%23fff' /%3E%3C/svg%3E");
    display: none;
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--color-black);
    border-radius: 4px;
    box-shadow: inset 1em 1em var(--color-black);
  }
}