.container {
  display: grid;
  font-size: var(--text-copy);
  line-height: var(--line-height-solid);
  grid-template-columns: 3fr 4fr;
  align-items: center;
  gap: var(--spacing-4);
}
.container picture {
  cursor: pointer;
}
.container img {
  object-fit: cover;
}

.content {
  width: 100%;
  display: grid;
  gap: var(--spacing-4);
  justify-items: start;
}

.link {
  cursor: pointer;
}

.link h4 {
  text-decoration: underline;
  text-decoration-color: var(--palette-text);
  text-decoration-thickness: 0.125em;
  text-underline-offset: 1.5px;
}

.list {
  display: grid;
  gap: var(--spacing-2);
}

.listItem {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-auto-flow: row;
  gap: var(--spacing-2);
  column-gap: var(--spacing-4);
}

.listItem span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.savings {
  display: grid;
  gap: var(--spacing-1);
  grid-auto-flow: column;
  font-size: 0.75rem;
}

.button {
  border: 0;
  font-size: 0.75rem;
  opacity: 0.5;
  line-height: 1.2;
  box-shadow: 0 1px 0 -0.5px var(--palette-background-dark-alt);
  transition: opacity 0.2s ease;
}
.button:hover {
  transition: opacity 0.2s ease;
  opacity: 1;
  cursor: pointer;
}

@media (min-width: 35em) {
  .container {
    gap: var(--spacing-6);
  }
}
