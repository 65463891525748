.footer {
  width: 100%;
  padding: var(--spacing-16) 0;
  background: var(--color-off-white);
}

.wrapper {
  width: min(90vw, 42em);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  font-size: var(--text-heading);
  line-height: 1.3;
  text-align: center;
  color: var(--color-black);
}

.formTitle {
  margin-top: var(--spacing-8);
  font-size: var(--text-copy);
  line-height: 1.3;
  text-align: center;
  color: var(--color-black);
}

.form {
  width: 100%;
  max-width: 375px;
  font-size: var(--text-copy);
  margin-top: var(--spacing-8);
}

.form form {
  width: 100%;
}

/* ===================START======================== */
/* These overrides are necessary to */
/* display the checkboxes as designed */

.form form > div > div:first-child {
  justify-content: center;
}

.form form > div > div:first-child > label > span {
  padding-right: 0;
  padding-left: var(--spacing-2);
}

.form form > div > div:nth-child(1) {
  border: none;
  border-bottom: 1px solid var(--color-black);
  padding: var(--spacing-2) 0;
}

.form input[type="email"] {
  padding: 0;
}

.form button {
  padding: 0;
  background: none;
  font-size: var(--text-copy);
  color: var(--color-black);
  flex: none;
}

/* ====================END======================= */

.message {
  font-size: var(--text-copy);
  margin-top: var(--spacing-2);
}

.links {
  text-align: center;
  font-size: var(--text-copy);
  line-height: 2.2;
  color: var(--color-black);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.links:first-child {
  margin-top: var(--spacing-10);
}

.link {
  white-space: nowrap;
  cursor: pointer;
  padding: 0 var(--spacing-3);
}

.logo {
  margin-top: var(--spacing-12);
  width: 2rem;
}

@media (min-width: 45em) {
  .header {
    font-size: 2rem;
    letter-spacing: -1px;
  }

  .formTitle {
    font-size: var(--text-heading);
    letter-spacing: -1px;
  }
}
