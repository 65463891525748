.input {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.wideRow {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.input input[type="checkbox"] {
  appearance: none;
  background-color: var(--color-white); /* For iOS < 15 to remove gradient background */
  margin: 0; /* Not removed via appearance */
  font: inherit;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-gray);
  border-radius: 2px;
  display: grid;
  place-content: center;
  margin-right: var(--spacing-2);
}

.input input[type="checkbox"]::before {
  content: url("data:image/svg+xml,%3Csvg width='8' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.695 2.28A1 1 0 0 0 .28 3.696l2 2a1 1 0 0 0 1.414 0l4-4A1 1 0 0 0 6.28.28L2.988 3.574 1.695 2.28Z' fill='%23fff' /%3E%3C/svg%3E");
  display: none;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--color-black);
  border-radius: 4px;
  box-shadow: inset 1em 1em var(--color-black);
}

.input input[type="checkbox"]:checked::before {
  display: flex;
  justify-content: center;
  align-items: center;
}