.header {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-5) var(--spacing-6);
  box-shadow: inset 0px -1px 0px var(--color-light-gray);
}

.title {
  font-size: var(--text-copy);
  line-height: var(--line-height-solid);
  color: var(--palette-text-alt);
  margin: 0;
  font-weight: normal;
}

.close {
  cursor: pointer;
  font-size: var(--text-copy);
  color: var(--palette-text-alt);
  margin: 0;
  line-height: var(--text-copy);
  font-weight: normal;
}
