.button {
  border: solid 1px var(--palette-border);
  padding: 1rem 2rem;
  display: inline-block;
  text-align: center;
  margin: 0;
}

.underline {
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}
