.wrapper {
  display: flex;
  place-items: stretch;
  grid-template-rows: 3rem;
}

.wrapper a {
  padding: 0 1rem;
  display: flex;
  place-items: center;
}
