.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
}

.block {
  display: block;
}
