.wrapper {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-content: center;
  grid-template-columns: repeat(auto-fit, 3rem);
  grid-template-rows: 3rem;
  place-items: center center;
}

.wrapper a {
  font-size: 0.875rem;
}

@media (min-width: 35em) {
  .wrapper {
    gap: 0;
    grid-template-columns: auto;
  }
  .wrapper a {
    line-height: 1rem;
    padding: 1rem 0.5rem;
  }
}
