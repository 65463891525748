.button {
  display: block;
  float: left;
  font-family: monospace;
  width: var(--spacing-4);
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: var(--text-copy);
  text-align: center;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.input {
  appearance: textfield;
  width: var(--spacing-12);
  height: 1.125rem;
  font-size: var(--text-copy);
  line-height: var(--line-height-solid);
  border: none;
  text-align: center;
  background: transparent;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
}
