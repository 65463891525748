.heading1 {
  font-family: Sohne Breit;
  font-size: clamp(2.5rem, 10vw, 4rem);
  letter-spacing: 0.01em;
  line-height: 1.1;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.heading2 {
  font-family: Sohne, system-ui, -apple-system, sans-serif;
  font-size: clamp(2.5rem, 10vw, 4rem);
  letter-spacing: 0.01em;
  line-height: 1.1;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.heading3 {
  font-family: Sohne Breit;
  font-size: clamp(2.5rem, 10vw, 4.25rem);
  letter-spacing: 0.01em;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.heading4 {
  font-family: Sohne Breit;
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  letter-spacing: 0;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.heading5 {
  font-family: Reckless;
  font-size: clamp(2rem, 5vw, 3.5rem);
  letter-spacing: 0;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.heading6 {
  font-family: Reckless;
  font-size: clamp(2rem, 5vw, 3.5rem);
  letter-spacing: 0;
  line-height: 1.1;
  text-transform: none;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.paragraph {
  font-family: Sohne, system-ui, -apple-system, sans-serif;
  font-size: clamp(1rem, 2vw, 1.25rem);
  letter-spacing: 0.01em;
  line-height: 1.4;
  text-transform: none;
  max-width: 30em;
  font-weight: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.caption {
  font-family: Sohne, system-ui, -apple-system, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.4;
  text-transform: none;
  max-width: 30em;
  font-weight: 200;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.quote {
  font-family: Reckless;
  font-size: clamp(2rem, 5vw, 3.5rem);
  line-height: 1.1;
  max-width: 30em;
  padding: 0 1em;
  margin-top: 3rem;
}

.quote + p {
  max-width: none;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.quoteCenter + p {
  text-align: center;
}

.callToAction {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
}

.callToAction a,
.callToAction span > a {
  text-decoration: none;
  border: solid 1px var(--color-black);
  padding: 1rem 2rem;
  display: inline-block;
  text-align: center;
  margin: 0;
}

.textCenter {
  text-align: center;
}

.marginCenter {
  margin: 0 auto;
}

.center {
  text-align: center;
  display: inline-block;
  width: 100%;
}
