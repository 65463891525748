.wrapper {
  position: fixed;
  top: 4.1rem;
  left: 0;
  grid-auto-flow: row;
  height: calc((var(--vh, 1vh) * 100) - 4.1rem);
  width: 100%;
  background: var(--color-white);
  padding: 0 1rem 8rem;
  /* padding: env(safe-area-inset-top) calc(1rem + env(safe-area-inset-right))
    calc(1rem + env(safe-area-inset-bottom)) calc(1rem + env(safe-area-inset-left)); */
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar{
  display: none;
}

.wrapper a {
  display: block;
  width: 100%;
}

.label {
  font-size: 0.625rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0.5rem 0;
  letter-spacing: 0.1rem;
}

.heading {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  width: 100%;
}

.link {
  font-size: 1rem;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title span,
.title a {
  padding: 0.5rem 0;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: none;
}
