@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid var(--palette-border);
  border-left: 4px solid;
  border-bottom-color: var(--color-dark-gray);
  animation: spin 2s infinite linear;
  border-radius: 50%;
  width: var(--spacing-6);
  height: var(--spacing-6);
}
