.base {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
}

.wrapper {
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 -1px 0 0 var(--color-light-gray) inset;
  gap: 1rem;
}

.navbar {
  grid-template-columns: 1fr auto 1fr;
  align-content: center;
  gap: 0.5rem;
}

@media (min-width: 35em) {
  .wrapper {
    padding: 0 0.5rem;
  }
  .navbar {
    gap: 1rem;
  }
}
