.wrapper {
  display: flex;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: var(--color-black);
  background-color: var(--color-off-white);
  padding: 0 1rem;
  height: 1.5rem;
}

.wrapper > div:first-of-type {
  justify-self: start;
}

.wrapper > div:last-child {
  justify-self: end;
}

.announcement {
  grid-column: 2;
  font-size: 0.75rem;
  text-align: center;
  width: 100%;
}

.announcement a {
  font-size: 0.75rem;
  line-height: 1;
  cursor: pointer;
}

.announcementLeft {
  display: none;
  grid-column: 1;
}

.announcementRight {
  display: none;
  grid-auto-flow: column;
  gap: var(--spacing-4);
  align-items: center;
  grid-column: 3;
  white-space: nowrap;
}

.announcementLink {
  display: flex;
}

.announcementLink a,
.announcementLink span {
  font-size: 0.75rem;
  line-height: 1;
  place-self: center;
  cursor: pointer;
}

@media (min-width: 35em) {
  .wrapper {
    display: grid;
  }

  .announcementLeft {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .announcementRight {
    display: grid;
  }
}
