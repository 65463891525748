.base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: var(--text-copy);
  line-height: var(--line-height-solid);
  font-family: var(--font-sans);
}

.secondary {
  border: 1px solid var(--color-black-alpha-10);
  border-radius: 2px;
  color: var(--palette-text-alt);
  height: 3.5rem;
  background: var(--color-white);
}

.secondary:focus,
.secondary:hover {
  border: 1px solid var(--palette-border);
}

.secondary:disabled {
  border: 1px solid var(--color-black-alpha-10);
  cursor: not-allowed;
  color: var(--color-black-alpha-20);
}

.primary {
  color: var(--palette-text-dark);
  background: var(--palette-background-dark-alt);
  height: 3.5rem;
}

.primary:focus,
.primary:hover {
  box-shadow: 0px 0px 0px 1px rgba(63, 63, 68, 0.05), 0px 1px 3px rgba(63, 63, 68, 0.15);
}

.primary:disabled {
  cursor: auto;
  background: var(--color-gray);
}

.tertiary {
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.tertiary:disabled {
  cursor: auto;
}

.full {
  width: 100%;
}

.hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  font-size: 0;
}
