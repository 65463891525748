.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--color-black-alpha-30);
  backdrop-filter: blur(4px);
  z-index: var(--z-layer-5);
}

.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: var(--palette-background);
  transform: translate(101%);
  transition: transform 0.3s ease;
  overflow: hidden;
  z-index: var(--z-layer-8);
}

.open {
  transform: translateX(0);
}

@media (min-width: 45em) {
  .container {
    top: 1.5rem;
    height: calc(100vh - 1.5rem);
    height: calc(100svh - 1.5rem);
    box-shadow: 1px 0 0 0 var(--color-light-gray) inset;
    max-width: 25rem;
  }
}
