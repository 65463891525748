@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.wrapper {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 4.5rem;
  height: calc(100vh - 4.5rem);
  height: calc(100svh - 4.5rem);
  transform: translateX(-100%);
  animation: slideIn 150ms ease 1;
  transition: transform 150ms ease;
  grid-auto-flow: row;
  width: 440px;
  background: white;
  padding: 2rem 1rem;
  overflow: auto;
}

.wrapper a {
  display: block;
  width: 100%;
}

.wrapper p {
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
}

.show {
  transform: translateX(0);
}

.subMenuWrapper {
  margin-bottom: 2rem;
}

.heading {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 1rem;
  width: 100%;
}

.list li {
  font-size: 20px;
  padding-bottom: 1rem;
  width: 100%;
}
.list li:last-child {
  padding-bottom: 0;
}