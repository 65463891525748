.container {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: fit-content;
  height: 1.5rem;
  box-sizing: border-box;
  border: 1px solid var(--color-light-gray);
  border-radius: 1px;
  margin-top: var(--spacing-4);
}

.container legend {
  font-size: 2px;
  opacity: 0;
  position: absolute;
}

.container input {
  opacity: 0;
  position: absolute;
}

.container label {
  background: var(--color-light-gray);
  box-shadow: 1px 0px 0px var(--color-light-gray), -1px 0px 0px var(--color-light-gray);

  padding: 12px 16px;
  font-size: 12px;
  display: inline-block;
  line-height: 2;
  position: relative;
}

/* .container:focus-within {
	box-shadow: 0 0 0 2px #fff, 0 0 0 4px #2196f3;
} */

.container label:hover {
  cursor: pointer;
}

.container input:checked + label {
  background-color: var(--palette-background);
  box-shadow: none;
}

@media (min-width: 45em) {
  .container {
    margin-top: 0;
  }
}
