.footer {
  background: var(--palette-background);
  font-size: var(--text-copy);
  line-height: var(--line-height-solid);
  width: 100%;
  position: sticky;
  bottom: env(safe-area-inset-bottom, 0);
  display: grid;
  gap: var(--spacing-2);
  text-align: center;
  box-shadow: 0px 1px 0px var(--color-light-gray) inset, 1px 0 0 var(--color-light-gray) inset;
  align-self: flex-end;
}

.footer p {
  padding-top: var(--spacing-2);
  font-size: var(--text-fine);
  color: var(--color-black);
  line-height: 1.2;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}
.footer button {
  height: 3.5rem;
  letter-spacing: 0.02em;
}


.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-2) var(--spacing-3);
  border-bottom: 1px solid var(--color-light-gray)
}

.row > div {
  flex: auto;
  text-align: left;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-2);
}
