.header,
.cartHeader {
  font-family: Sohne, system-ui, -apple-system, sans-serif;
  text-align: center;
  font-size: var(--text-heading);
  padding: var(--spacing-6) var(--spacing-2);
  color: var(--color-darker-gray);
  border-top: 1px solid var(--color-light-gray);
  border-bottom: 1px solid var(--color-light-gray);
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 160px), 1fr));
  gap: 1px;
  background-color: var(--color-light-gray);
}

.button {
  width: 100%;
  padding: var(--spacing-5);
  border-top: 1px solid var(--color-light-gray);
  border-bottom: 1px solid var(--color-light-gray);
  font-size: var(--text-copy);
  color: var(--color-black);
}

@media (min-width: 45em) {
  .header {
    font-size: 2rem;
    padding: var(--spacing-16) var(--spacing-2);
  }
}