.container {
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 300ms;
}

.container img {
  width: 100%;
}

/* ==================== */
/* IMAGES */
/* ==================== */

.imageSection {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 150%;
  animation: fadeIn 350ms ease-in;
  overflow: hidden;
}

.imageContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.imageContainer picture:nth-of-type(2) {
  opacity: 0;
}

.imageContainer:hover picture:nth-of-type(1):not(:only-child) {
  opacity: 0;
}

.imageContainer:hover picture:nth-of-type(2) {
  opacity: 1;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.full {
  transition: opacity 400ms ease 0ms;
}

.specialText {
  position: relative;
  z-index: 20;
  padding: var(--spacing-4) var(--spacing-4) 0 var(--spacing-4);
  font-size: var(--text-copy);
  color: var(--color-orange);
}

.specialText + .specialText {
  padding-top: 0;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}

.loadingMessage {
  padding: var(--spacing-4);
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  color: var(--color-white);
}

/* ==================== */
/* FOOTER */
/* ==================== */

.footer {
  width: 100%;
  z-index: 20;
  position: relative;
}

.text,
.title {
  font-size: var(--text-copy);
  font-weight: 400;
  line-height: 1.25rem;
  white-space: nowrap;
}

.rating {
  white-space: nowrap;
  margin-right: var(--spacing-4);
}

.content {
  flex: 1;
  display: grid;
  padding: var(--spacing-2) 0;
}

@media (min-width: 45rem) {
  .content {
    gap: var(--spacing-2);
    padding: var(--spacing-4) 0;
  }
}

.title {
  --max-lines: 1;
  --lh: 1.5rem;
  padding: 0 var(--spacing-3);
  line-height: var(--lh);
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

@media (min-width: 45rem) {
  .title {
    padding: 0 var(--spacing-4);
  }
}

.sizes {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-1) 0 var(--spacing-1) var(--spacing-3);
  border-top: 1px solid var(--color-light-gray);
  border-bottom: 1px solid var(--color-light-gray);
  background: var(--color-white);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  z-index: 5;
  font-size: var(--text-copy);
  display: none;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@media (min-width: 45rem) {
  .sizes {
    padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-4);
  }
}

.sizes::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.sizeContainer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-height: 2rem;
}

.sizeList {
  display: grid;
  gap: var(--spacing-2);
  width: auto;
  grid-auto-flow: column;
  align-items: center;
  padding: 0 var(--spacing-2);
}

.sizeTitle {
  flex-shrink: 0;
}

.showSizes {
  display: flex;
}

.sizeBtn {
  font-size: var(--text-copy);
  padding: 0.375rem;
}

.sizeBtnDisabled {
  opacity: 0.5;
  cursor: initial;
}

.sizeBtnSelected {
  outline: 1px solid #000000;
}

.innerContainer {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  background: var(--color-white);
}

.details {
  display: flex;
  height: 1.5rem;
  padding-left: var(--spacing-3);
  align-items: center;
}

@media (min-width: 45rem) {
  .details {
    padding-left: var(--spacing-4);
  }
}

.price {
  gap: var(--spacing-2);
  margin-right: var(--spacing-2);
}

@media (min-width: 45rem) {
  .price {
    margin-right: var(--spacing-4);
  }
}

.price,
.colours {
  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-3);
  align-items: center;
  height: 1.5rem;
  overflow: visible;
}

.colours {
  display: none;
}

.showColours {
  display: grid;
  justify-content: start;
}

.showColours > li:last-child {
  padding-right: 3rem;
}

.hideText {
  display: none;
}

.colourBtn {
  width: var(--spacing-4);
  height: var(--spacing-4);
  border-radius: 100%;
  overflow: auto;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15);
}

.colourBtnSelected {
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px var(--color-black),
    inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.colourListItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price p:nth-of-type(2) {
  text-decoration: line-through;
}

.toggleBtn {
  position: absolute;
  right: 0;
  padding: var(--spacing-3);
  transition: 100ms ease-in-out;
  background-color: var(--color-white);
  box-shadow: -1px 0 2px 0px var(--color-white), -1px 0 4px 0px var(--color-white);
}

.toggleBtn > svg {
  transition: transform 1000ms ease-in-out;
  transform: rotate(0deg);
}

.toggleBtnOpen > svg {
  transform: rotate(45deg);
}

@media only screen and (max-width: 48em) {
  .rating {
    display: none;
  }
}

.colours {
  flex: 1;
  padding: var(--spacing-1);
  width: min(10rem, 4.5rem);
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.colours::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

@media (hover: hover) {
  .imageSection:hover .sliderBtn {
    display: block;
  }

  .container:hover .showHoverImage {
    opacity: 1;
  }

  .container:hover .sizes {
    display: flex;
  }

  .container:hover .sizes > a {
    padding-right: var(--spacing-4);
  }

  .container:hover .colours {
    display: flex;
    width: 100%;
  }

  .container:hover .colourText {
    display: none;
  }

  .toggleBtn {
    display: none;
  }
}

@media only screen and (max-width: 35em) {
  .sizes {
    padding: 0 var(--spacing-3);
  }

  .container:hover .sizes > a {
    padding-right: var(--spacing-3);
  }

  .colours::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
  }
}
