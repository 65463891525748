.container {
  font-size: var(--text-copy);
  line-height: var(--line-height-copy);
  display: grid;
  gap: var(--spacing-4);
  padding: var(--spacing-2);
}

.container a {
  border-bottom: solid 1px var(--palette-background-dark-alt);
}